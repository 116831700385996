<template>
  <div class="page-content">
    <breadcrumb title="Events" bgImage="/assets/bg/events.jpg" :hideOnMobile="true">
      <events-breadcrumb @changedSelectedDestination="changedSelectedDestination" @changedSelectedCategory="changedSelectedCategory" @changedSelectedTimeSpan="changedSelectedTimeSpan" @changedSelectedDate="changedSelectedDate" :cat="dataSet.query.keyword" :timeframe="dataSet.query.selectedTimeFrame" @resetFilters="resetFilters" />
    </breadcrumb>

    <div class="d-lg-none">
      <div class="filter-bar">
        <form action="#!" class="row align-items-center">
          <div class="col-12 col-sm-6 col-md-4 mb-3 mb-sm-0">
            <date-selector @dateChanged="changedSelectedDate"/>
          </div>
<!--
          <div class="col-12 col-sm-6 mb-3">
            <label class="text-center w-100 mb-0">Suchzeitraum von</label>
            <date-selector @dateChanged="changedSelectedStartDate" :inputDate="new Date(dataSet.query.selectedStartDate)" />
          </div>

          <div class="col-12 col-sm-6 mb-3">
            <label class="text-center w-100 mb-0">Suchzeitraum bis</label>
            <date-selector @dateChanged="changedSelectedEndDate" :inputDate="new Date(dataSet.query.selectedEndDate)" />
          </div>

        -->

        <div class="col-6 grid-workaround">
          <time-span-selector class="time-span" @changedSelectedTimeSpan="changedSelectedTimeSpan" :timeframe="dataSet.query.selectedTimeFrame"/>
        </div>

        <div class="col-6 grid-workaround">
          <button type="button" class="btn btn-simple btn-icon btn-filter filter-btn" @click="filterDropdown = !filterDropdown"> 
            <span v-if="this.selectedCategoriesCount>0" class="dot">{{selectedCategoriesCount}}</span>Filter
            <span class="material-icons">{{ filterDropdown ? 'expand_less' : 'expand_more' }}</span>
          </button>
        </div>
      </form>
    </div>
    <div v-if="filterDropdown" class="filter-dropdown d-lg-none">
      <filter-options :dropdown="true" @changedSelectedDestination="changedSelectedDestination" @changedSelectedCategories="changedSelectedCategory" :cat="dataSet.query.keyword" @closeThis="filterDropdown=false" />
    </div>
  </div>

  <div class="container-fluid">
    <card-slider v-if="isset(item.contents)" v-for="(item, index) in categoryContentList" :key="index" :title="item.category" :data="item.contents" card="event" :largeCards="true" />
  </div>
  <!-- Footer Margin -->
  <div class="footer-margin"></div>
</div>
</template>

<script>
  import { latLng } from 'leaflet';
  import { getContentsOfType } from '@/api/content';
  import { showLoader, hideLoader, getFirstFieldValue, sortByProperty, argsFilterMapToJSMap, isset } from '@/utils/helpers';
  import { getCategoryContentList, enhanceContentAttribsWithCategory } from '@/utils/tripmeister-helpers';
  import { getFilterDate, compareAndSwitchDates, getReadableTime } from '@/utils/appointment-helpers';

  export default {
    name: 'EventView',
    components: {
      Breadcrumb: () => import('@/components/layout/Breadcrumb'),
      EventsBreadcrumb: () => import('@/components/forms/EventsBreadcrumb'),
      CardSlider: () => import('@/components/CardSlider'),
      FilterOptions: () => import('@/components/FilterOptions'),
      TimeSpanSelector: () => import('@/components/controls/TimeSpanSelector'),
      DateSelector: () => import('@/components/controls/DateSelector'),
    },
    data() {
      return {
        loader: null,
        contents: null,
        showBreadcrumb: true,
        dataSet: {
          total: 0,
          data: null,
          meta:null,
          query: {
            page:1,
            limit: 150,
            keyword: '',
            type: 'view',
            onlyWithLocation: true,
            selectedTimeFrame: null,
            selectedDate: new Date(),
            selectedAppId: this.$appId,
            view_status: 1,
            filters: null,
            selectedContentTypeId: this.$offerId,
            selectedLatLng: {
              point: null,
              distance: this.$radius,
            },
            sort: {
              prop: '',
              order: 'asc'
            },
          },
        },
        filterDropdown: false,
        selectedFilters: new Map(),
        dateTimeSelection:{
          selectedStartDate: new Date(),
          selectedEndDate: new Date(new Date().setDate(new Date().getDate() + 7)),
          selectedTimeFrame: null
        }
      }
    },
    created() {
      if(isset(this.uriFilters)){
        var gna = JSON.parse(this.uriFilters);
        this.dataSet.query.selectedStartDate = gna.start;
        this.dataSet.query.selectedEndDate = gna.end;
        this.dataSet.query.selectedTimeFrame = gna.timeframe;
        this.dataSet.query.keyword = gna.cat;
      }
      //this.applyOldFilters(filters);
      this.getContents();
    },
    computed: {
      selectedCategoriesCount(){
        if(this.$store.getters.getSelectedCategories !== null){
          return this.$store.getters.getSelectedCategories.length;
        }
      },
      uriFilters(){
        return this.$route.query.filters;
      },
      destinationName () {
        var name = this.$route.params.destination.replace('/destination/', '').replace('-', ' ').toUpperCase();
        return decodeURIComponent(name);
      },
      categoryContentList(){
       return getCategoryContentList(this.contents);
     },
     storedDestination(){
      return this.$store.getters.getSelectedDestination;
    },
  },
  methods: {
    isset,
    showLoader,
    hideLoader,
    sortByProperty,
    getFilterDate,
    compareAndSwitchDates,
    getReadableTime,
    async getContents() {
      var gna = new Object();
      gna.date = this.dataSet.query.selectedDate;
      gna.cat = this.dataSet.query.keyword;
      gna.timeframe = this.dataSet.query.selectedTimeFrame;
      /*history.pushState({},null,this.$route.path + '?filters=' + encodeURIComponent(JSON.stringify(gna)));*/

      this.loader = this.showLoader(this.loader);
      const { limit, page } = this.dataSet.query;
      this.dataSet.query.selectedLatLng.point=latLng(this.storedDestination.coords.lat,this.storedDestination.coords.long);
      const { data, meta } = await getContentsOfType(this.$offerId,this.dataSet.query);
      this.dataSet.data = data;
      this.dataSet.data.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.dataSet.meta = meta;
      this.dataSet.total = meta.total;
      this.enhanceContentAttribsWithCategory();
      this.contents = this.sortContentsByCategories();
      this.loader = this.hideLoader(this.loader);
    },
    filterChanged(){
      this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
    },
    applyOldFilters(map){
      if(isset(map.get('start'))){
        this.changedSelectedStartDate(new Date(map.get('start')));
        this.dateTimeSelection.selectedEndDate = new Date(map.get('end'));
        console.log(map.get('end'))
      }
      if(isset(map.get('cat'))){
        this.changedSelectedCategory(map.get('cat'));
      }
      if(isset(map.get('timeframe'))){
        this.changedSelectedTimeSpan(map.get('timeframe'));
      }
      this.filterChanged();
    },
    enhanceContentAttribsWithCategory(){
      enhanceContentAttribsWithCategory(this.dataSet.data);
    },
    sortContentsByCategories(){
      return sortByProperty(this.dataSet.data, 'category', 'de');
    },
    changedSelectedDestination(destination){
      this.getContents();
      console.log(destination);
      this.$router.push({ name: 'EventIndex', params: {destination: encodeURIComponent(this.storedDestination.name) } });
    },
    changedSelectedCategory(category){
      this.dataSet.query.keyword = category;
      this.selectedFilters.set('cat', category);
      this.filterChanged();
      this.getContents();
    },
    changedSelectedTimeSpan(selectedTimeFrame){
      this.dataSet.query.selectedTimeFrame = selectedTimeFrame;
      this.selectedFilters.set('timeframe', selectedTimeFrame);
      this.filterChanged();
      this.getContents();
    },
    changedSelectedDate(selectedDate){
      this.dataSet.query.selectedDate = selectedDate;
      this.getContents();
    },
    setDateQuery(){
      this.dataSet.query.selectedStartDate = getFilterDate(this.dateTimeSelection.selectedStartDate);
      this.dataSet.query.selectedEndDate = getFilterDate(this.dateTimeSelection.selectedEndDate);
      this.selectedFilters.set('start', this.dataSet.query.selectedStartDate);
      this.selectedFilters.set('end', this.dataSet.query.selectedEndDate);
      this.filterChanged();
      this.getContents();
    },
    resetFilters(){
      this.dataSet.query.selectedDate = new Date();
      this.dataSet.query.keyword = '';
      this.dataSet.query.selectedTimeFrame = null;
      this.getContents();
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .time-span {
   @media(max-width:991px){
    width: 100% !important;
    justify-content: center;
  }
}

.time-select {
 @media(max-width:991px){
  padding: none;
}
}

.time-icon {
 @media(max-width:991px){
  position: inherit !important;
}
}

.btn-filter {
 @media(max-width:991px){
  margin: 0 auto;
  float: none !important;
}
}

.grid-workaround {
  @media(max-width: 430px){
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.card{
  width: 400px !important;
  margin-bottom: 30px !important;
}

.dot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  background-color: $primary;
  color: #FFF;
  border-radius: 50%;
  position: absolute;
  bottom: 10px;
  left: 0px;
  font-size: 11px;
}

.filter-btn {
  padding-left: 17px;
}

.breadcrumb {
  height: 220px;

  h1 {
    font-size: 10px;
  }
}

</style>