  import { showLoader, hideLoader, sortByProperty, getFirstFieldValue } from '@/utils/helpers';

  export function extractMerklisten(data,limit=3,exclude=null){
      if(data !== null && data !== undefined){
        var merklisten = [];
        for(var i = 0; i < data.length; i++){
          var contents = data[i].contents;
          if(contents !== null && contents.length>=3){
            /*exclude a certain Merkliste*/
            if(exclude!==null ){
              if(data[i].id!==exclude){
                merklisten.push(data[i]);
            }
        }
        else{
          merklisten.push(data[i]);
      }
  }

  /*we only need three results*/
  if(merklisten.length>=limit){
    break;
}
}  
return merklisten;
}
return null;
}

/**
 * get a placeholder image if no other image is given
 */
export function getCategoryPlaceholderImage(category = null){
    /*we pack all the images in an array so we can return a random one*/
    var images = [
        "/assets/highlights/thumbnails/gastro.jpg",
        "/assets/highlights/thumbnails/kultur.jpg",
        "/assets/highlights/thumbnails/shopping.jpg",
        "/assets/highlights/thumbnails/outdoor.jpg",
        "/assets/highlights/thumbnails/wellness.jpg",
        "/assets/highlights/thumbnails/sightseeing.jpg",
        "/assets/highlights/thumbnails/informationen.jpg",
        "/assets/highlights/thumbnails/freizeit.jpg",
        "/assets/highlights/thumbnails/oeffentliche orte.jpg",
        "/assets/highlights/thumbnails/logo-eck.jpg"
        ];

    var image = null;

    /*if a category is given we'll look for an image matching that category*/
    if(category != null){
        switch(category){
        case "Gastronomie":
            image = images[0];
            break;
        case "Essen & Trinken":
            image = images[0];
            break;
        case "Kultur":
            image = images[1];
            break;
        case "Shopping":
            image = images[2];
            break;
        case "Outdoor & Aktiv":
            image = images[3];
            break;
        case "Wellness":
            image = images[4];
            break;
        case "Sightseeing":
            image = images[5];
            break;
        case "Informationen":
            image = images[6];
            break;
        case "Freizeit":
            image = images[7];
            break;
        case "Öffentliche Orte":
            image = images[8];
            break;
        case "Unterkünfte":
            image = images[9];
            break;
        default:
            image = images[Math.floor(Math.random()*images.length)];
            break;
        }
    }

    if(image == null){
        image = images[Math.floor(Math.random()*images.length)];
    }

    return image;
}

/**
 * get a placeholder image if no other image is given
 */
export function getLocationPlaceholderImage(category = null){
    /*we pack all the images in an array so we can return a random one*/
    var images = [
        "/assets/locations/thumbnails/flensburg.jpg",
        "/assets/locations/thumbnails/luebeck.jpg",
        "/assets/locations/thumbnails/kiel.jpg",
        "/assets/locations/thumbnails/eckernfoerde.jpg",
        "/assets/locations/thumbnails/schleswig.jpg",
        "/assets/locations/thumbnails/binnenland.jpg"];

    var image = null;

    /*if a category is given we'll look for an image matching that category*/
    if(category != null){
        switch(category){
        case "Flensburger Fjord":
            image = images[0];
            break;
        case "Lübecker Bucht":
            image = images[1];
            break;
        case "Kiel Sailing City":
            image = images[2];
            break;
        case "Eckernförder Bucht":
            image = images[3];
            break;
        case "Ostseefjord Schlei":
            image = images[4];
            break;
        case "Grünes Binnenland":
            image = images[5];
            break;
        default:
            image = images[Math.floor(Math.random()*images.length)];
            break;
        }
    }

    if(image == null){
        image = images[Math.floor(Math.random()*images.length)];
    }

    return image;
}

/*Helper-Function to extend which links should be marked as active in the navigation*/
export function checkIfLinkActive(elem,currentRoute){

    if(currentRoute==null){
        return false;
    }

    if(elem=="Locations" && currentRoute.includes("Location")){
        return true;
    }

    if(elem=="Events" && currentRoute.includes("Event")){
        return true;
    }

    if(elem=="Reisepläne" && currentRoute.includes("Trip")){
        return true;
    }

    return false;
}


export function enhanceContentAttribsWithCategory(data){
    for(var k = 0; k < data.length; k++){
      var content = data[k];
      /*get the categories from this content*/
      var cats = getFirstFieldValue(content,'kategorien');
      if(cats!=null && cats.length>0){
        content.category = cats;
    }
}
}


export function getCategoryContentList(contents){
    if(contents != null){

        /*we need to order the institutions into an array of objects by the first letter*/
        /*init*/
        var resultSet = [];
        var singleResultSet = {};
        var cat = null;

        for(var i = 0; i < contents.length; i++){
            var content = contents[i];
            /*let's check if the current letter is the same as the first letter from the title of the institution*/
            if(content.category != cat){
                /*check if the current letter is null. If that is not the case, we'll push the singleResultSet onto the resultSet*/
                if(cat!=null){
                    resultSet.push(singleResultSet);
                }

                /*set the letter for the comparison*/
                cat = content.category;

                singleResultSet = { 
                    category: content.category,
                    contents: [],
                };
            }

            /*add the current institution to the singleResultSet*/
            singleResultSet.contents.push(content);

        }
        /*at the end of the for-loop there is still one last singleResultSet that is not part of the overall result set.*/
        resultSet.push(singleResultSet);
        return resultSet;
    }
    return null;

}
